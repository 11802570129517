import * as React from "react";
import { CloudIcon, ServerIcon, WifiIcon } from "@heroicons/react/solid";

import PageTitle from "../../components/elements/PageTitle";
import Header from "../../components/sections/Header";
import ModelPageSection from "../../components/sections/ModelPageSection";
import AttachmentsSection from "../../components/sections/AttachmentsSection";
import AttachmentItem from "../../components/elements/AttachmentItem";
import HighlightsSection from "../../components/sections/HighlightsSection";
import HighlightItem from "../../components/elements/HighlightItem";
import DistributorsSection from "../../components/sections/DistributorsSection";
import DistributorItem from "../../components/elements/DistributorItem";
import Footer from "../../components/sections/Footer";

import certificateFile from "../../images/C2Y-CU-001-01-39 ÄB16 Certifikat_typeС.jpg";
import techSpecFile from "../../images/tech_spec_posplus_II.png";
import LinkSection from "../../components/sections/LinkSection";

const ModelCEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"se"} pageTitle={"Model C"} description={""} />
    <Header lang={"se"} />
    <ModelPageSection
      titleSection={"Modell C"}
      descriptionSection={""}
      modelTitle={"Modell C – För server och flera kassor"}
      modelDescription={`PosPlus Modell C är avsedd för att kopplas till en central server, lokalt eller via internet och på så sätt fungera som kontrollenhet för en mängd kassor. PosPlus tillhandahåller lösningar både för molnserver och lokal server.`}
      modelImgName={"posplus_blue.jpg"}
      certificatesText={[`För komplett lista av certifikat tryck`, `här`]}
      // faqs={[
      //   {

      //   },
      // ]}
      licenseSectionTitle="Licens"
      licenseTitles={["Certifikat C UNIT"]}
      licenseContent={[
        `Posplus är en certifierad produkt vars etikett visar vilket certifikat som gäller för produkten.
        Här följer ett exempel på certifikat:`,
      ]}
      certificatesUrl={"https://www.cert2you.se/lista-certifikat-2020"}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      certificateImgNames={["certifikat-posplus-scaled.jpg", "C2Y-CU-001-01-39 ÄB16 Certifikat_typeС.jpg"]}
      techSpecContent={[
        `Posplus is a certified product whose label shows which
                          certificate applies to the product. Here is an example
                          of a certificate:`,
      ]}
      // just add name of photo like this ["certifikat-posplus-scaled.jpg", "certifikat-posplus-scaled.jpg"]
      techSpecImgNames={["tech_spec_posplus_II.png"]}
    >
      <AttachmentsSection title={"Bilagor"}>
        <AttachmentItem
          fileName={"Certifikat"}
          file={certificateFile}
          downloadText="Ladda ner"
        />
        <AttachmentItem
          fileName={"Teknisk specifikation"}
          file={techSpecFile}
          downloadText="Ladda ner"
        />
      </AttachmentsSection>

      <LinkSection
        title="Kommunikation protokoll"
        /*description="description"*/
        clickText={["För att hitta en beskrivning av kommunikationsprotokollet för REST-API, klicka ", "här"]}
        linkPath="/se/api-doc"
      />

      <LinkSection
        title="Felkoder"
        /* description="description"*/
        clickText={["För att hitta en fullständig lista över felkoder och deras definitioner, klicka ", "här"]}
        linkPath="/se/error-codes"
      />

      <HighlightsSection title={"Serverlösningar"}>
        <HighlightItem
          name="Molnserver"
          description={`Du kan även koppla din utrustning till PosPlus kontrollenhet genom vår Molnserver-tjänst.`}
          IconImg={CloudIcon}
        />
        <HighlightItem
          name="Lokal server"
          description={`Du kan även använda PosPlus (Modell C) med en egen lokal server.`}
          IconImg={ServerIcon}
        />
        <HighlightItem
          name="Bluetooth-anslutning"
          description={`PosPlus kan anslutas till ett mobilt kassaregister via Bluetooth.`}
          IconImg={WifiIcon}
        />
      </HighlightsSection>

      <DistributorsSection title={"Distributörer"}>
        <DistributorItem
          title={"Postronic AB"}
          email={"info@postronic.se"}
          phone={"+46 (0) 7 0 594 29 54"}
        />
      </DistributorsSection>
    </ModelPageSection>
    <Footer lang={"se"} />
  </div>
);

export default ModelCEnPage;
